<template>
  <div class="card">
    <h5 class="card-header text-truncate w-100">Entradas recientes de inventario</h5>
    <div class="card-body">
      <div class="table-responsive">
        <table class="table mb-0 table-nowrap" id="entradas-recientes" style="min-height: 250px;">
          <thead>
            <tr>
              <th style="width:40px"></th>
              <th>Nombre</th>
              <th>Cantidad</th>
              <th>Fecha de entrada</th>
              <th>Entrada</th>
            </tr>
          </thead>
          <tbody>
            <tr style="font-size: 15px;" v-for="movimiento in movimientos" :key="movimiento.id">
              <th scope="row">
                <a
                  href="#"
                  onclick="return false;"
                  @click="mostarGaleriaFotosArticulo(movimiento.id_articulo)"
                  v-if="movimiento.foto_principal_articulo"
                >
                  <img
                    :src="`${API}/articulos/${movimiento.id_articulo}/fotos/principal?tipo_foto=64x64&_tk=${tk}`"
                    style="width:30px; height:30px;"
                    class="rounded-circle"
                  />
                </a>
                <img
                  :src="inventarioIcon"
                  v-if="!movimiento.foto_principal_articulo"
                  style="width:30px; height:30px;"
                  class="rounded-circle"
                />
              </th>
              <td>
                {{movimiento.nombre_articulo}}
              </td>
              <td>
                {{movimiento.cantidad}}
                {{movimiento.abreviatura_unidad_medida}}
              </td>
              <td>
                {{formatoFecha(movimiento.fecha)}}
              </td>    
              <td style="width:180px;">
                <router-link :to="{ name: 'visorEntradaAlmacen', params: { id: movimiento.id_entrada } }"
                  class="btn btn-outline-secondary btn-sm">
                  <i class="mdi mdi-eye"></i>
                  Mostrar entrada {{movimiento.id_entalm}}
                </router-link>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <cmp-galeria-fotos 
      ref="entradas_recientes_articulo"
      :nombreGaleria="'entradas_recientes_articulo'"
    />
  </div>
</template>

<script>
import MovimientoArticulosSrv from '@/services/inventario/MovimientoArticulosSrv.js'
import inventarioIcon from '@/assets/img/inventario/inventario.png'
import CmpGaleriaFotos from "../CmpGaleriaFotos.vue"
import {APIINV as API} from '@/API.js'

import moment from 'moment'

export default {
  name: 'WEntradasRecientesInventario',
  components: {
    CmpGaleriaFotos
  },
  data() {
    return {
      API: API,
      tk: localStorage.getItem('argusblack.token'),
      inventarioIcon: inventarioIcon,
      movimientos: []
    }
  },
  created(){
    var self = this
    setTimeout(() => {
      self.refrescarMovimientos()
    }, 500)

  },

  methods: {
    mostarGaleriaFotosArticulo: function(idArticulo) {
      var self = this
      self.$refs.entradas_recientes_articulo.mostrarGaleria(idArticulo)
    },

    formatoFecha(mt) {
      return moment(mt, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY')
    },
    
    refrescarMovimientos: function() {
      var self = this

      iu.spinner.mostrar('#entradas-recientes')

      let params = {
        limite:5
      }
      
      MovimientoArticulosSrv.ultimasEntradasJSON(params).then(response => {
        let page = response.data
        self.movimientos = page
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudieron cargar los ultimos movimientos de entradas'
        }
        iu.msg.error(mensaje)
        console.log(error)
      }).finally (() => {
        iu.spinner.ocultar('#entradas-recientes')
      })
    }
  },
  mounted: function() {
    iu.spinner.mostrar('#entradas-recientes')
  }
}
</script>

<style scoped>
</style>