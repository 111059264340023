<template>
  <div class="card">
    <h5 class="card-header text-truncate w-100">
      Solicitudes
    </h5>
    <div class="card-body">
      <div class="row">
        <div class="col-md-6 col-6 text-center">
          <router-link :to="{ name: 'listadoSolicitudes' }" title="Solicitudes pendientes">
            <div class="avatar mx-auto">
              <div class="avatar-title rounded-circle bg-info bg-gradient font-size-20" :title="total_solicitudes_pendientes">
                {{ total_solicitudes_pendientes <= 9999 ? total_solicitudes_pendientes : '999+'}}
              </div>
            </div>
            <div class="text-info text-center">
              <span class="d-block d-sm-none text-truncate w-100">
                Pendientes
              </span>
              <span class="d-none d-sm-block text-truncate w-100">
                Solicitudes pendientes
              </span>
            </div>
          </router-link>
        </div>
        <div class="col-md-6 col-6 text-center">
          <router-link :to="{ name: 'listadoSolicitudes', query:{ estado: 2 } }" title="Solicitudes en proceso">
            <div class="avatar mx-auto">
              <div class="avatar-title rounded-circle bg-warning bg-gradient font-size-20" :title="total_solitudes_en_proceso">
                {{ total_solitudes_en_proceso <= 9999 ? total_solitudes_en_proceso : '999+' }}
              </div>
            </div>
            <div class="text-warning text-center">
              <span class="d-block d-sm-none text-truncate w-100">
                En proceso
              </span>
              <span class="d-none d-sm-block text-truncate w-100">
                Solicitudes en proceso
              </span>
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SolicitudArticulosSrv from '@/services/inventario/SolicitudArticulosSrv.js'

export default {
  name: 'WSolicitudes',
  props: {
    tienePermiso: {
      type: Function,
      requerid: true
    }
  },
  data() {
    return {
      total_solicitudes_pendientes: 0,
      total_solitudes_en_proceso: 0,
      intervals: {
        actualizacion: null
      }
    }
  },
  created() {
    var self = this

    this.refrescar()
    this.intervals.actualizacion = setInterval(function() {
      self.refrescar()
    }, 60000)
  },
  beforeUnmount() {
    clearInterval(this.intervals.actualizacion)
  },
  methods: {
    refrescar() {
      var self = this
      
      let params1 ={
        estado:1
      }
      
      if(!self.tienePermiso('cmpFiltroUsuarioSolicitud', 'componente')){
        Object.assign(params1,{id_solicitante: self.$store.state.todo.usuario.id})
      }
      
      SolicitudArticulosSrv.cantidad(params1).then(response => {
        self.total_solicitudes_pendientes = response.data
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = ''
        }
        mensaje != '' ? iu.msg.error(mensaje) : null
        console.log(error)
      })

      let params2 ={
        estado:2
      }

      if(!self.tienePermiso('cmpFiltroUsuarioSolicitud', 'componente')){
        Object.assign(params2,{id_solicitante: self.$store.state.todo.usuario.id})
      } 

      SolicitudArticulosSrv.cantidad(params2).then(response => {
        self.total_solitudes_en_proceso = response.data
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = ''
        }
        mensaje != '' ? iu.msg.error(mensaje) : null
        console.log(error)
      })
    }
  }
}
</script>

<style scoped>

</style>