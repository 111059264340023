<template>
  <Layout :tituloPagina="'Inicio Inventario'">
    <div class="row" v-show="!$store.state.todo.susargus">
      <div class="col-lg-12">
        <div class="card bg-warning text-white">
          <div class="card-body text-center">
            <p class="card-text">
              Su suscripción ha caducado, debe de pagar su mensualidad para seguir disfrutando de ArgusBlack, 
              visite la sección <a href="/sistema/cuenta/" style="color: red;">CUENTA</a> para renovarla en este momento.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-xxl-6 col-lg-6 col-sm-6" v-if="tienePermiso('cmpSolicitudesInventario','componente')">
        <WSolicitudes :tienePermiso="tienePermiso"></WSolicitudes>
      </div>
      <div class="col-xxl-3 col-lg-3 col-sm-6" v-if="tienePermiso('cmpFamiliaInventario','componente')">
        <WFamilia></WFamilia>
      </div>
      <div class="col-xxl-3 col-lg-3 col-sm-6" v-if="tienePermiso('cmpArticuloInventario','componente')">
        <WArticulo></WArticulo>
      </div>
    </div>

    <div class="row">
      <div class="col-xxl-6" v-if="tienePermiso('cmpWEntradasRecientesInventarioInventario','componente')">
        <WEntradasRecientesInventario></WEntradasRecientesInventario>
      </div>
      <div class="col-xxl-6" v-if="tienePermiso('cmpSalidasRecientesInventarioInventario','componente')">
        <WSalidasRecientesInventario></WSalidasRecientesInventario>
      </div>
    </div>

    <div class="row">
      <div class="col-xxl-6" v-if="tienePermiso('cmpArticulosBajaInventario','componente')">
        <WArticulosBaja></WArticulosBaja>
      </div>
      <div class="col-xxl-6" v-if="tienePermiso('cmpArticulosSinExistenciasInventario','componente')">
        <WArticulosSinExistencias></WArticulosSinExistencias>
      </div>
    </div>
    <br>
  </Layout>
</template>

<script>
import Layout from "@/views/layouts/main.vue"

import WSolicitudes             from './WSolicitudes.vue'
import WFamilia                 from './WFamilia.vue'
import WArticulo                from './WArticulo.vue'
import WEntradasRecientesInventario from './WEntradasRecientesInventario.vue'
import WSalidasRecientesInventario  from './WSalidasRecientesInventario.vue'
import WArticulosBaja           from './WArticulosBaja.vue'
import WArticulosSinExistencias from './WArticulosSinExistencias.vue'

import { todoGetters } from "@/state/helpers";

export default {
  name: 'Inicio',
  components: {
    Layout,
    WSolicitudes,
    WArticulosBaja,
    WArticulo,
    WFamilia,
    WEntradasRecientesInventario,
    WSalidasRecientesInventario,
    WArticulosSinExistencias
  },
  computed: { ...todoGetters },
}
</script>