<template>
  <div class="card">
    <h5 class="card-header text-truncate w-100">Familias</h5>
    <div class="card-body">
      <div class="row">
        <div class="col-md-12 text-center">
          <router-link :to="{ name: 'listadoFamiliaArticulos' }" title="Falimia de artículos">
            <div class="avatar mx-auto">
              <div class="avatar-title rounded-circle bg-danger bg-gradient font-size-20" :title="total_familia_articulo">
                {{ total_familia_articulo <= 9999 ? total_familia_articulo : '999+'}}
              </div>
            </div>
            <div class="text-danger text-center text-truncate w-100">
              Familia de artículos
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FamiliaArticulosSrv from '@/services/inventario/FamiliaArticulosSrv.js'
export default {
  name: 'WFamilia',

  data() {
    return {
      total_familia_articulo: 0,
      intervals: {
        actualizacion: null
      }
    }
  },
  created() {
    var self = this

    this.actualizar()
    this.intervals.actualizacion = setInterval(function() {
      self.actualizar()
    }, 60000)
  },
  beforeUnmount() {
    clearInterval(this.intervals.actualizacion)
  },

  methods: {
    actualizar() {
      var self = this

      FamiliaArticulosSrv.cantidad().then(response => {
        self.total_familia_articulo = response.data
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = ''
        }
        mensaje != '' ? iu.msg.error(mensaje) : null
        console.log(error)
      })
    }
  },
}
</script>

<style scoped>

</style>