<template>
  <div class="card">
    <h5 class="card-header text-truncate w-100">Artículos con existencia baja</h5>
    <div class="card-body">
      <div class="table-responsive">
        <table class="table mb-0 table-nowrap" id="articulo-bajas" style="min-height: 250px;">
          <thead>
            <tr>
              <th style="width:50px"></th>
              <th>Nombre del artículo</th>
              <th>Stock total</th>
              <th>Stock mínimo</th>
            </tr>
          </thead>
          <tbody>
            <tr style="font-size: 15px;" v-for="(articulo,index) in articulos" :key="`articulos-${index+1}`">
              <td>
                <div style="min-height: 30px; display: inline-block">
                  <a
                    href="#"
                    onclick="return false;"
                    @click="mostarGaleriaFotosArticulo(articulo.id)"
                    v-if="articulo.foto_principal"
                  >
                    <img
                      :src="`${API}/articulos/${articulo.id}/fotos/principal?tipo_foto=64x64&_tk=${tk}`"
                      style="width:30px; height:30px;"
                      class="rounded-circle"
                    />
                  </a>
                  <img
                    :src="inventarioIcon"
                    v-if="!articulo.foto_principal"
                    style="width:30px; height:30px;"
                    class="rounded-circle"
                  />
                </div>
              </td>
              <td>
                {{articulo.nombre}}
              </td>
              <td>
                {{articulo.stock_total}} {{articulo.unidad_medida.abreviatura}}
              </td>
              <td>
                {{articulo.stock_minimo}} {{articulo.unidad_medida.abreviatura}}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <br>
      <div class="row">
        <div class="col-12 text-right">
          <router-link
            class="btn btn-outline-secondary btn-sm text-truncate"
            :to="{
              name: 'listadoArticulos',
              query: {
                tipo_existencia: 4
              }
            }"
          >
            <i class="mdi mdi-eye"></i>
            mostrar lista completa
          </router-link>
        </div>
      </div>
    </div>
    <cmp-galeria-fotos 
      ref="galeria_existencias_bajas_articulo"
      :nombreGaleria="`galeria-existencias-bajas-articulo`"
    />
  </div>
</template>

<script>
import ArticuloSrv from '@/services/inventario/ArticuloSrv.js'
import inventarioIcon from '@/assets/img/inventario/inventario.png'
import CmpGaleriaFotos from "../CmpGaleriaFotos.vue"
import {APIINV as API} from '@/API.js'

export default {
  name: 'WArticulosBaja',
  components: {
    CmpGaleriaFotos
  },
  data() {
    return {
      API: API,
      tk: localStorage.getItem('argusblack.token'),
      inventarioIcon: inventarioIcon,
      articulos: []
    }
  },
  created() {
    var self = this

    self.refrescarArticulos()
  },
  methods: {
    mostarGaleriaFotosArticulo: function(idArticulo) {
      var self = this
      self.$refs.galeria_existencias_bajas_articulo.mostrarGaleria(idArticulo)
    },

    refrescarArticulos: function() {
      var self = this

      iu.spinner.mostrar('#articulo-bajas')

      let params = {
        sin_paginacion: true,
        con_existencia_baja: true,
        con_unidad_medida: true,
        limite:10
      }

      ArticuloSrv.articulosJSON(params).then(response => {
        let articulos = response.data
        self.articulos = articulos
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudieron cargar las familias de articulos'
        }
        iu.msg.error(mensaje)
        console.log(error)
      }).finally (() => {
        iu.spinner.ocultar('#articulo-bajas')
      })
    }
  },
  mounted: function() {
    iu.spinner.mostrar('#articulo-bajas')
  }
}
</script>

<style scoped>
.text-truncate {
  width: 30%;
}
</style>