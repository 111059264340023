<template>
  <div class="card">
    <h5 class="card-header text-truncate w-100">Artículos</h5>
    <div class="card-body">
      <div class="row">
        <div class="col-md-12 text-center">
          <router-link :to="{ name: 'listadoArticulos' }" title="Todos los artículos">
            <div class="avatar mx-auto">
              <div class="avatar-title rounded-circle bg-warning bg-gradient font-size-20" :title="todos_articulos">
                {{ todos_articulos <= 9999 ? todos_articulos : '999+' }}
              </div>
            </div>
            <div class="text-warning text-center text-truncate w-100">
              Todos los artículos
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ArticuloSrv from '@/services/inventario/ArticuloSrv.js'
export default {
  name: 'WArticulo',

  data() {
    return {
      todos_articulos: 0,
      intervals: {
        actualizacion: null
      }
    }
  },
  created() {
    var self = this

    this.actualizar()
    this.intervals.actualizacion = setInterval(function() {
      self.actualizar()
    }, 60000)
  },
  beforeUnmount() {
    clearInterval(this.intervals.actualizacion)
  },
  methods: {
    actualizar() {
      var self = this

      ArticuloSrv.cantidad().then(response => {
        self.todos_articulos = response.data
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = ''
        }
        mensaje != '' ? iu.msg.error(mensaje): null
        console.log(error)
      })
    }
  }
}
</script>

<style scoped>

</style>