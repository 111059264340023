import axios from 'axios'
import { APIINV as API } from '@/API.js'

const apiClient = axios.create({
  baseURL: `${API}/solicitudes-articulos`,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: localStorage.getItem('argusblack.token')
  }
})

// Actualización del token de autorización
document.addEventListener('actualizar:token', function(e) {
  apiClient.defaults.headers['Authorization'] = e.detail
})

// Eliminación del token de autorización
document.addEventListener('eliminar:token', function() {
  apiClient.defaults.headers['Authorization'] = null
})

export default {
  actualizar(solicitud) {
    return apiClient.patch(`/${solicitud.id}`, solicitud)
  },

  actualizarPartida(idSolicitud, partida) {
    return apiClient.patch(`${idSolicitud}/partidas/${partida.id}`, partida)
  },

  cancelar(idSolicitud) {
    return apiClient.post(`/${idSolicitud}/cancelar`)
  },

  cantidad(params) {
    params = params || {}
    return apiClient.get('cantidad',{params: params})
  },

  cerrar(idSolicitud, solicitud) {
    return apiClient.post(`${idSolicitud}/cerrar`, solicitud)
  },

  darSalida(idSolicitud,solicitud) {
    return apiClient.post(`${idSolicitud}/dar-salida`, solicitud)
  },

  eliminar(idSolicitud) {
    return apiClient.delete(`/${idSolicitud}`)
  },

  guardar(solicitud) {
    return apiClient.post('', solicitud)
  },
  

  solicitudesJSON(params) {
    params = params || {}
    return apiClient.get(`${API}/solicitudes-articulos.json`, { params: params })
  },

  partidaJSON(idSolicitud, idPartida, params){
    params = params || {}
    return apiClient.get(`${idSolicitud}/partidas/${idPartida}.json`, { params: params })
  },

  solicitudJSON(idSolicitud,params) {
    params = params || {}
    return apiClient.get(`${idSolicitud}.json`,{params:params})
  },
}